// Global packages and components
import { clsx } from 'clsx'

// Types
import type { SanityCustomImage } from '~/types'

// Main export
const BannerImage = ({
  image,
  absolute = true,
}: {
  image: SanityCustomImage
  absolute?: boolean
}) => (
  <div
    className={clsx('left-0 top-0 h-full w-full overflow-hidden rounded-2xl', {
      absolute: absolute,
      relative: !absolute,
    })}
  >
    <div className="absolute left-0 top-0 z-[1] h-full w-full bg-gradient-to-t from-black/60 to-transparent" />
    {image?.asset?.url && (
      <img
        className="h-full w-full object-cover"
        src={image.asset.url}
        alt={image?.alt}
      />
    )}
  </div>
)

export default BannerImage
